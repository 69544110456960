import React from 'react';
import { Link}  from 'react-router-dom';

export default () => {    

    return (
        <div className="link-list">
            <div className="link-list__title">Select resolution</div>
            <ul>
                <li><Link to="/1464x836">1464x836</Link></li>
                <li><Link to="/1920x1080">1920x1080</Link></li>
                <li><Link to="/1080x1920">1080x1920</Link></li>
                <li><Link to="/708x256">708x256</Link></li>
            </ul>
        </div>
    );
}

