import React from 'react';
import config from '../config';
import * as fbapi from '../services/fbapi';

// https://github.com/mac-s-g/js-playground
// https://github.com/chenglou/react-motion


export default class Counter extends React.Component {

    constructor(props) {
        super(props);

        //console.log(props)

        this.state = {
            counter: 0
        }
        this.type = props.match.params.type;
        //console.log(this.type)
        this.params = config.types[this.type] ? config.types[this.type] : false;


        this.timer = null;
        this.updateCounter = this.updateCounter.bind(this);
        this.setTimer = this.setTimer.bind(this);
    }

    setTimer() {
        this.timer = setTimeout(this.updateCounter, config.timeout * 1000);
    }

    updateCounter() {
        const fn = process.env.PUBLIC_URL + '/images/' + this.params.fn;
        fbapi.getLikeCount((values) => {
            const cnt = values.fan_count;
            const cntOld = this.state.counter;
            this.setState({counter: cnt});
            //console.log('updated ' + values.fan_count);
            this.setTimer();

            if (cntOld !== cnt) {

                //const add = 'Z ';
                const oldValue = cntOld.toString().padStart(7, '0');
                const newValue = cnt.toString().padStart(7, '0');

                window.$('.counter-aeroport').splitFlap({
                    image: fn,
                    charsMap: '0123456789',
                    charWidth: this.params.width,
                    charHeight: this.params.height,
                    text: newValue,
                    textInit: oldValue,
                    onComplete: function () {
                        //console.log('Done !');
                    }
                });
            }
        });
    }

    componentDidMount() {
        if (!this.params) {
            return;
        }
        this.updateCounter();
    }

    componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    render() {
        const { counter } = this.state;
        if (!this.params) {
            return 'Unknown resolution';
        }        
        return (
            <div className={'counter counter--' + this.type}>

                <div className="counter__clouds">
                    <div className="cloud cloud--type1 cloud--1"></div>
                    <div className="cloud cloud--type2 cloud--2"></div>
                    <div className="cloud cloud--type1 cloud--3"></div>
                    <div className="cloud cloud--type1 cloud--4"></div>
                </div>
                <div className="counter__top"></div>

                {counter > 0 && (
                    <a href="https://www.facebook.com/afisha.NYC" target="_blank" rel="noopener noreferrer" className="counter-aeroport">
                        {counter}
                    </a>
                )}

                <div className="counter__glass"></div>

            </div>
        )
    }
}