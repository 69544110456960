import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Counter from './Counter';
import Main from './Main';
export default class App extends React.Component {
    
    render() {
        return (
            <div className="afishacnt">
                <Switch>
                    <Route exact path="/" component={Main} />
                    <Route path="/:type" component={Counter} />
                </Switch>
            </div>
        )
    }

}