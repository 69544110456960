import '@babel/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './components/App';

import './config.js';
import './styles.css';

const basename = '/';// '/afishacounter/';

ReactDOM.render(
    <BrowserRouter basename={basename}><App /></BrowserRouter>,
    document.getElementById('root')
);
  