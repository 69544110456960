import fetchJsonp from 'fetch-jsonp';
import config from '../config';

export const getLikeCount = (cb) => {

    const q = config.networkConfig.api_url;
    fetchJsonp(q)
    .then(function(response) {
        return response.json();
    })
    .then(function(data) {
        //console.log(q, data);
        if (cb) {
            cb(data);
        }
    })
    .catch((error) => {
        console.log('error', error);
    });
}
